import { Button } from "@chakra-ui/react";
import React, { useState } from "react";

export const UploadDocWithUploadButton = ({
  inputId,
  uploadFileFunction,
  label,
  field,
  generateLabel,
  generateFunction,
  generate = false,
}: any) => {
  const [errMsg, setErrorMsg] = useState("");
  return (
    <div>
      <div className="flex flex-col ">
        <div className="flex justify-between">
          <label className="block">{label}</label>
        </div>
        {generate ? (
          <Button
            className="block rounded-sm bg-blue-500 px-1.5 text-white  hover:bg-blue-600 active:bg-blue-800"
            onClick={() => generateFunction()}
          >
            {generateLabel}
          </Button>
        ) : (
          <div className="inline-block rounded-md border-2 px-2 py-[1.3rem]">
            <input
              id={inputId}
              type="file"
              className=""
              onFocus={() => setErrorMsg("")}
              accept="application/pdf"
            />
            <button
              type="button"
              className=" ml-1 rounded-sm bg-blue-500 px-2 py-1 text-sm text-white hover:bg-blue-600 active:bg-blue-800"
              onClick={(e) => {
                e.preventDefault();
                const fileInput: any = document.getElementById(inputId);
                if (fileInput && fileInput.files.length > 0) {
                  uploadFileFunction(fileInput.files[0], field);
                } else {
                  setErrorMsg("Please Select File!");
                }
              }}
            >
              Upload
            </button>
          </div>
        )}
      </div>
      {errMsg && <label className="ml-2 text-red-500">{errMsg}</label>}
    </div>
  );
};
