import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Filter } from "assets/jsx/Filter";
import Card from "components/card";
import { DownloadButton } from "components/custom/DownloadButton";
import { FilterFields } from "components/custom/FilterFields";
import {
  columnsTable,
  downloadFile,
  handleKeyPress,
  snakeToCamelCase,
} from "helper/helperFunctions";
import React, { useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteResource, getAllResources } from "services/appServices";
import { errorToast, infoToast, successToast } from "views/toastConfig";
import Edit from "../../../assets/img/edit.png";
import Eye from "../../../assets/img/eye.png";
import Thrash from "../../../assets/img/thrash.png";
import { ResourceStatus, Role } from "../../customTypes/types";
import RequestDetails from "../RequestDetailPage/RequestDetails";
import { NoData } from "components/custom/NoData";

interface fieldType {
  key: string;
  type: "text" | "select" | "date";
  label: string;
  placeholder: string;
  options?: { value: string; label: string }[];
  collection: string;
}

const initialFields = [
  "organizationInfo",
  "updatedAt",
  "allotementStatus",
  "conversation",
  "userData",
  "resourceData",
  "requestLetter",
  "_id",
  "resourceId",
  "idCardImg",
  "otherDoc",
  "panCardImg",
  "gstCertificateImg",
  "aadharCardImg",
  "emailVerified",
  "mobVerified",
  "allotmentLetter",
  "paymentReferenceDetails",
  "bankReceipt",
  "finalPaymentReceipt",
  "otherDoc",
  "oldRecord",
  "uid",
  "paymentdocuments",
  "finalVerficationDocument",
  "resourceDetails",
  "isCustomCharge",
  "vacationReport",
  "prevResourceDetails",
  "accountStatus",
  "paymentVerficationDocument",
  "personnelInfo",
];

const additionalExcludedFieldsForAllResources = [
  "role",
  "name",
  "type",
  "staffNo",
  "designation",
  "fatherOrHusbandName",
  "permanentAddress",
  "organizationContactNumber",
  "organizationAddress",
  "contractNo",
  "rent",
  "waterCharges",
  "maintenance",
  "fromDate",
  "tillDate",
  "rentalCode",
  "monthOfPossession",
  "requestType",
  "customerCode",
  "remark",
  "dob",
  "gender",
  "occupation",
  "organizationName",
  "reportingOfficerContactNumber",
  "isActive",
  "reportingOfficer",
  "createdAt",
  "paySlipImg"
];

export default function Resources() {
  // const { user } = useSelector((store: any) => store.auth);
  const { userRole } = useSelector((store: any) => store.appSlice);
  const [isPageChanged, setIsPageChanged] = useState<boolean>(false);
  const [onPageLoadDataLength, setOnPageLoadDataLength] = useState<number>(0);

  const [excludeDownloadFields, setExcludeFields] = useState(initialFields);

  const isInitialRender = useRef(true);
  const navigate = useNavigate();
  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const [currentResource, setCurrentResource] = useState(
    snakeToCamelCase(pathParts[pathParts.length - 1])
  );
  const [pageType, setPageType] = useState(pathParts[pathParts.length - 2]);
  //?table state
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimt] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [occupiedCount, setOccupiedCount] = useState(0);
  const [vacantCount, setVacantCount] = useState(0);
  const [appliedCount, setAppliedCount] = useState(0);
  const [searchFilter, setSearchFilter] = useState<{ [key: string]: any }>({});
  const [isFilterOpen, setIsFilteOpen] = useState<boolean>(false);
  const [rowData, setRowData] = useState<any>();
  const [isRequestDetailPageOpen, setIsRequestDetailPageOpen] = useState(false);
  const [data, setData] = React.useState([]);
  let filters: fieldType[] = [];
  if (currentResource === "quarter") {
    filters = [
      {
        key: "buildingType",
        type: "text",
        label: "Building Type",
        placeholder: "Enter Building Type",
        collection: "resources",
        options: [
          { value: "A", label: "A" },
          { value: "B", label: "B" },
          { value: "C", label: "C" },
          { value: "D", label: "D" },
        ],
      },
      {
        key: "buildingNumber",
        type: "text",
        label: "Building No.",
        placeholder: "Enter Building No.",
        collection: "resources",
      },
      {
        key: "sectorNumber",
        type: "text",
        label: "Sector No.",
        placeholder: "Enter Sector No.",
        collection: "resources",
      },
      {
        key: "roomNo",
        type: "text",
        label: "Room No.",
        placeholder: "Enter Room No.",
        collection: "resources",
      },
    ];

    if (pageType !== "alloted_resources") {
      filters.push({
        key: "status",
        type: "select",
        label: "Resource Status",
        placeholder: "Select Type",
        collection: "resources",
        options: [
          { value: "alloted", label: "Alloted" },
          { value: "vacant", label: "Vacant" },
          { value: "applied", label: "Applied" },
        ],
      });
    }

    // Uncomment and add additional filters if needed
    // filters.push(
    //     {
    //         key: "name",
    //         type: "text",
    //         label: "Enter Customer Name",
    //         placeholder: "Enter text",
    //         collection: "user",
    //     },
    //     {
    //         key: "occupation",
    //         type: "text",
    //         label: "Enter Occupation",
    //         placeholder: "Enter text",
    //         collection: "user",
    //     },
    //     {
    //         key: "date",
    //         type: "date",
    //         label: "Date",
    //         placeholder: "Enter Date",
    //         collection: "user",
    //     }
    // );
  } else {
    filters = [];
  }

  const getResources = async (isDownload = false) => {
    try {
      let filter = {};
      //*filter based on current page
      //? manager approval first time
      switch (pageType) {
        case "availble_resources":
          filter = {
            status: ResourceStatus.VACANT,
            resourceType: `${currentResource}`,
          };
          break;

        case "alloted_resources":
          filter = {
            resourceType: `${currentResource}`,
            status: ResourceStatus.ALLOTED,
          };
          break;

        case "all_resources":
          filter = {
            resourceType: `${currentResource}`,
          };
          break;

        default:
          // Handle the case where pageType doesn't match any known value
          filter = {};
          console.warn(`Unknown pageType: ${pageType}`);
          break;
      }
      if (!searchFilter?.global) {
        delete searchFilter.global;
      }
      const res = await getAllResources(
        { ...filter, searchFilter },
        !isDownload ? currentPage : null,
        !isDownload ? limit : null
      );
      const { payload, message, error, statusCode } = res?.data || {};

      if (statusCode == 200) {
        const formatedData: any = payload.documents.map(
          ({ userData, allotmentRequestData, resourceData }: any) => {
            return {
              ...userData,
              ...resourceData,
              ...allotmentRequestData,
              resourceData,
              userData,
            };
          }
        );

        if (payload.documents) {
          if (isDownload) {
            downloadFile(
              formatedData,
              `${pageType}_Data-${new Date().toLocaleDateString()}`,
              excludeDownloadFields //? Excluded data
            );
          } else {
            console.log({ data: payload });
            setData(formatedData);
            setTotalCount(payload.counts.totalCount || 0);
            setAppliedCount(payload.counts.statusCounts?.applied || 0);
            setVacantCount(payload.counts.statusCounts?.vacant || 0);
            setOccupiedCount(payload.counts.statusCounts?.alloted || 0);
          }
        }
      } else {
        //? this will come from backend
        console.log({ error });
        errorToast(message);
      }
    } catch (error: any) {
      console.log("Error getResources Resources ==>", { error });
      //? in direct catch : if any errro occured at frontend level
      errorToast(error.response?.data?.message || error.message);
    }
  };

  const handleDelete = async (rowData: any) => {
    const excludedKeys = ["createdAt", "updatedAt"];

    if (
      rowData.status == ResourceStatus.ALLOTED ||
      rowData.status == ResourceStatus.APPLIED
    ) {
      window.alert("Resouce is not Vacant!");
      return;
    }
    const isConfirmed = window.confirm(
      `Are you sure you want to delete ?\n${Object.entries(rowData)
        .filter(([key]) => !excludedKeys?.includes(key)) // Exclude specified keys
        .map(([key, value]) => `${key}: ${value}`)
        .join("\n")}`
    );
    if (isConfirmed) {
      try {
        const res = await deleteResource(rowData._id);
        if (res.data?._id) {
          successToast("Deleted Successfully");
          data.length! <= 1
            ? setCurrentPage((prev: number) => --prev)
            : getResources();
        } else {
          throw Error(res.data.message);
        }
      } catch (error: any) {
        console.log("Error handleDelete Resources ==>", { error });
        errorToast(error.response?.data?.message || error.message);
      }
    }
  };

  //*table Data

  useEffect(() => {
    if (Object.keys(searchFilter).length > 0) {
      setCurrentPage(1);
    }
  }, [searchFilter]);

  //* to refresh route changes as we user same component to disp all resources data
  useEffect(() => {
    getResources();
    console.log({ currentPage, pageType });
  }, [currentPage, isPageChanged]);

  useEffect(() => {
    if (
      pageType === "all_resources" &&
      !["alloted", "applied"].includes(searchFilter?.resources?.status)
    ) {
      setExcludeFields((prevFields) =>
        Array.from(
          new Set([...prevFields, ...additionalExcludedFieldsForAllResources])
        )
      );
    } else {
      setExcludeFields(initialFields);
    }
  }, [pageType, searchFilter]);

  useEffect(() => {
    if (!isInitialRender.current) {
      setCurrentPage(1);
      setData([]);
      setTotalCount(0);
      setAppliedCount(0);
      setOccupiedCount(0);
      setVacantCount(0);
      setPageType(pathParts[pathParts.length - 2]);
      setCurrentResource(snakeToCamelCase(pathParts[pathParts.length - 1]));
      setSearchFilter([]);
      setIsPageChanged((prev) => !prev);
      setIsFilteOpen(false);
      setIsRequestDetailPageOpen(false);
    } else {
      isInitialRender.current = false;
    }
    setOnPageLoadDataLength(0);
  }, [location.pathname]);

  //? Generating colums dynamically

  const columns = columnsTable(
    data,
    [],
    [
      "_id",
      "createdAt",
      "updatedAt",
      "resourceData",
      "userData",
      "uid",
      "allotementStatus",
      "type",
      "staffNo",
      "designation",
      "dob",
      "fatherOrHusbandName",
      "gender",
      "occupation",
      "permanentAddress",
      "mobNo",
      "organizationName",
      "organizationContactNumber",
      "reportingOfficer",
      "reportingOfficerContactNumber",
      "organizationAddress",
      "personnelInfo",
      "organizationInfo",
      "oldRecord",
      "createdAt",
      "allotmentLetter",
      "rentalUnit",
      "resourceId",
      "remark",
      "role",
      "resourceDetails",
      "resourceType",
      "documents",
      "conversation",
      "paymentReferenceDetails",
      "requestLetter",
      "otherDoc",
      "idCardImg",
      "panCardImg",
      "gstCertificateImg",
      "aadharCardImg",
      "emailVerified",
      "mobVerified",
      "finalPaymentReceipt",
      "bankReceipt",
      "guarantorDetails",
      "isActive",
      "requestType",
      "paymentVerficationDocument",
      "paymentdocuments",
      "finalVerficationDocument",
      "waterCharges",
      "fromDate",
      "tillDate",
      "monthOfPossession",
      "accountStatus",
    ]
  );

  //? Add action column
  // !["all_resources"]?.includes(pageType) &&
  columns.splice(columns.length, 0, {
    accessor: "action",
    id: "action",
    header: () => (
      <p className=" text-center text-sm font-bold  dark:text-white">Action</p>
    ),
    cell: (info) => (
      <div className=" flex w-full min-w-36 items-center justify-center ">
        <img
          src={Eye}
          className="hover:cursor-pointer"
          alt="view"
          onClick={() => {
            // navigate("requests/details", { state: info.row.original })
            setRowData(info.row.original);
            setIsRequestDetailPageOpen(true);
          }}
        />

        {[Role.ADMIN, Role.CLEARK]?.includes(userRole) && (
          <>
            <img
              src={Edit}
              className=" mx-1 hover:cursor-pointer"
              onClick={() => {
                if (info.row.original.status != ResourceStatus.VACANT) {
                  infoToast("Resource is Not Vacant!");
                  return;
                }
                navigate("edit", { state: info.row.original });
              }}
              alt="edit"
            />
            {pageType != "alloted_resources" && (
              <img
                className=" mx-1 hover:cursor-pointer"
                src={Thrash}
                onClick={() => handleDelete(info.row.original)}
                alt="delete"
              />
            )}
          </>
        )}
      </div>
    ),
  });

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  useEffect(() => {
    if (!onPageLoadDataLength) {
      setOnPageLoadDataLength(data.length);
    }
  }, [data.length]);
  return !isRequestDetailPageOpen ? (
    <>
      {data.length || onPageLoadDataLength ? (
        <>
          <Card extra={"h-full px-6 pb-6 relative"}>
            {/* //@search/filter/add section */}
            <div className="  mt-4  flex   md:h-11 md:self-end ">
              <div className="flex h-full w-full flex-col space-y-2 md:flex-row md:space-y-0">
                {/*//* Search section */}

                {onPageLoadDataLength ? (
                  <div className="flex items-center justify-between rounded-md bg-gray-100 px-2 md:mr-2 md:h-full">
                    <input
                      value={searchFilter?.global || ""}
                      onChange={(e) =>
                        setSearchFilter((prev) => ({
                          ...prev,
                          global: e.target.value,
                        }))
                      }
                      onKeyDown={(e) => handleKeyPress(e, getResources)}
                      type="text"
                      placeholder="Search..."
                      className="text-md block h-[2.4rem]  bg-gray-100 font-medium text-navy-700 outline-none placeholder:text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:text-white  md:w-fit"
                    />
                    <div
                      onClick={() => {
                        searchFilter.global && getResources();
                      }}
                      className="flex cursor-pointer"
                    >
                      <FiSearch />
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="flex flex-col space-y-2  md:h-full md:flex-row-reverse md:space-y-0">
                  {[Role.ADMIN, Role.CLEARK]?.includes(userRole) &&
                    !["alloted_resources"]?.includes(pageType) && (
                      <div className="flex  flex-1 items-center justify-between space-x-2 md:ml-2  ">
                        <button
                          className="  w-full rounded-md bg-green-700 px-6 py-2 text-white"
                          onClick={() => {
                            navigate("add", {
                              state: [],
                            });
                          }}
                        >
                          Add
                        </button>

                        <button
                          className=" w-full text-nowrap rounded-md bg-green-500 px-5 py-2 text-white"
                          onClick={() => {
                            navigate("add_from_csv", {
                              state: [],
                            });
                          }}
                        >
                          Add from File (.xlsx)
                        </button>
                      </div>
                    )}

                  <div className="mx-auto flex  items-center  justify-between space-x-2 sm-max:space-x-1">
                    {pageType == "alloted_resources" && (
                      <div className=" flex flex-row items-center space-x-1 rounded-md bg-[#B4EDFF] px-1  py-2 text-[#3692b4] ">
                        <span className="text-sm font-bold">Total </span>
                        <span className="text-sm font-semibold">
                          {totalCount}
                        </span>
                      </div>
                    )}
                    {data.length != 0 && (
                      <DownloadButton
                        data={data}
                        getAllData={getResources}
                        fileName={`${pageType}_Data-${new Date().toLocaleDateString()}`}
                        excludeFields={excludeDownloadFields}
                      />
                    )}
                    <div
                      className=" cursor-pointer shadow-md shadow-[#d4e9ff]"
                      onClick={() => setIsFilteOpen((prev) => !prev)}
                    >
                      <Filter />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isFilterOpen && (
              <FilterFields
                filters={filters}
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                cancleFunction={setIsFilteOpen}
                searchFunction={getResources}
                onKeyDown={(e: any) => handleKeyPress(e, getResources)}
              />
            )}
            {pageType !== "alloted_resources" && (
              <div className=" mt-4 flex justify-between 3xl:absolute ">
                {/* //@ info section */}
                <div className=" flex w-full flex-1 items-center  text-sm font-bold sm:space-x-5 sm-max:justify-around  ">
                  <>
                    <div className="  flex flex-col items-center rounded-md bg-[#B4EDFF] py-1  text-[#3692b4] sm:min-w-16 sm-max:min-w-14 md:min-w-20">
                      <span>Total </span>
                      <span>{totalCount}</span>
                    </div>
                    <div className=" flex flex-col items-center rounded-md bg-[#B4EDFF] py-1  text-[#3692b4] sm:min-w-16 sm-max:min-w-14 md:min-w-20">
                      <span>Alloted </span>
                      <span>{occupiedCount}</span>
                    </div>

                    <div className=" flex flex-col items-center rounded-md bg-[#78e2af] py-1  text-[#1f5929] sm:min-w-16 sm-max:min-w-14 md:min-w-20">
                      <span>Vacant </span>
                      <span>{vacantCount}</span>
                    </div>
                    <div className=" flex flex-col items-center rounded-md bg-[#76f2dd] py-1  text-[#2a8072] sm:min-w-16 sm-max:min-w-14 md:min-w-20">
                      <span>Applied </span>
                      <span>{appliedCount}</span>
                    </div>
                  </>
                </div>
              </div>
            )}

            <div className="mt-8 overflow-x-auto">
              {data.length > 0 && (
                <table className="mt-2 w-full">
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr
                        key={headerGroup.id}
                        className="!border-px !border-gray-400"
                      >
                        {headerGroup.headers.map((header) => (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            onClick={header.column.getToggleSortingHandler()}
                            className="mb-2 cursor-pointer border-b-[1px] border-gray-200 bg-gradient-to-t from-[#ffffff] to-[#c7fdff] py-2 text-center align-top shadow-lg"
                          >
                            <div className=" items-center justify-between  text-center text-xs text-[#00353C]">
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: "",
                                desc: "",
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table
                      .getRowModel()
                      .rows.slice(0, limit)
                      .map((row) => (
                        <tr key={row.id}>
                          {row.getVisibleCells().map((cell) => (
                            <td
                              key={cell.id}
                              className="border-x border-y-0 px-1 py-3 text-center"
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </Card>

          {data.length > 0 && (
            <ReactPaginate
              className="mt-2 flex justify-center gap-2"
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={Math.ceil(totalCount / limit)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={({ selected }) => setCurrentPage(selected + 1)}
              containerClassName={"pagination"}
              activeClassName={"text-primaryColor-dark font-bold "}
            />
          )}
        </>
      ) : (
        <div className="flex float-end flex-col space-y-2  md:h-full md:flex-row-reverse md:space-y-0">
        {[Role.ADMIN, Role.CLEARK]?.includes(userRole) &&
                    !["alloted_resources"]?.includes(pageType) && (
                      <div className="flex w  flex-1 items-center justify-end space-x-2 md:ml-2  ">
                        <button
                          className="  w-[10rem] rounded-md bg-green-700 px-6 py-2 text-white"
                          onClick={() => {
                            navigate("add", {
                              state: [],
                            });
                          }}
                        >
                          Add
                        </button>

                        <button
                          className=" w-[12rem] text-nowrap rounded-md bg-green-600 px-5 py-2 text-white"
                          onClick={() => {
                            navigate("add_from_csv", {
                              state: [],
                            });
                          }}
                        >
                          Add from File (.xlsx)
                        </button>
                      </div>
                    )}
        </div>
      )}

      <NoData isVisible={data.length == 0} label={"No Resource found!"} />
    </>
  ) : (
    <RequestDetails
      rowData={rowData}
      backFuction={setIsRequestDetailPageOpen}
    />
  );
}
