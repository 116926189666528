import { useEffect, useState } from "react";
import { errorToast, infoToast, successToast } from "views/toastConfig";
import { getEmailList, updateEmailList } from "services/appServices";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Card from "components/card";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";

interface StageData {
  to: string[];
  cc: string[];
  bcc: string[];
  desc:string
}

interface LatestData {
  [stageKey: string]: StageData;
}



export const EmailList = () => {
  const [latestData, setLatestData] = useState<LatestData | null>(null);

  const getHistoryData = async () => {
    try {
      const res = await getEmailList();
      const { payload, message, error, statusCode } = res?.data || {};
      if (statusCode === 200) {
        if (!payload) {
          infoToast("No Record found!");
          return;
        }
        setLatestData(payload);
      } else {
        errorToast(message);
      }
    } catch (error: any) {
      errorToast(error.message || error.response?.data?.message);
    }
  };

  useEffect(() => {
    getHistoryData();
  }, []);

  const initialValues: Record<string, StageData> = latestData
    ? Object.entries(latestData).reduce((acc, [stageKey, stageData]) => {
        acc[stageKey] = {
          to: stageData.to || [],
          cc: stageData.cc || [],
          bcc: stageData.bcc || [],
          desc:stageData.desc
        };
        return acc;
      }, {} as Record<string, StageData>)
    : {};

  const validationSchema = Yup.object().shape(
    Object.keys(initialValues).reduce<Record<string, any>>((acc, stageKey) => {
      acc[stageKey] = Yup.object().shape({
        to: Yup.array().of(Yup.string().email("Invalid email format")),
        cc: Yup.array().of(Yup.string().email("Invalid email format")),
        bcc: Yup.array().of(Yup.string().email("Invalid email format")),
      });
      return acc;
    }, {})
  );

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      console.log("Updated Data:", values);
      await updateEmailList(values);
      successToast("Email list updated successfully!");
      getHistoryData();
    } catch (error: any) {
      errorToast(error.message || "Error updating email list");
    }
    setSubmitting(false);
  };

  return (
    <Card className="mb-10 mt-2 h-full w-full overflow-x-auto px-6 pb-6">
      {latestData ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting }) => (
            <Form>
              {Object.entries(values).map(([stageKey, stageData],index) => (
                <div key={stageKey} className="mt-8 overflow-x-auto">
                  <h3 className="text-lg font-bold text-gray-700">
                    <span className="text-[#15263fcc]">Stage {index}</span> : {stageData.desc}
                  </h3>
                  <table className="w-full">
                    <thead>
                      <tr className="!border-px !border-gray-400">
                        <th className="border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start">
                          To
                        </th>
                        <th className="border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start">
                          CC
                        </th>
                        <th className="border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start">
                          BCC
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {["to", "cc", "bcc"].map((field) => (
                          <td
                            key={field}
                            className="min-w-[150px] border-white/0 py-3 pr-4"
                          >
                            <FieldArray name={`${stageKey}.${field}`}>
                              {({ push, remove, form }) => (
                                <div>
                                  {form.values[stageKey][field].map(
                                    (email: string, index: number) => (
                                      <div
                                        key={index}
                                        className="mb-2 flex flex-col items-center gap-2"
                                      >
                                        <div className="relative w-full">
                                          <Field
                                            name={`${stageKey}.${field}.${index}`}
                                            placeholder="Enter Email"
                                            className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                                          />
                                          <button
                                            type="button"
                                            onClick={() => remove(index)}
                                            className="absolute right-2 top-[50%] -translate-y-1/2 rounded bg-red-500 px-3 py-1 text-white"
                                          >
                                            -
                                          </button>
                                        </div>
                                        <ErrorMessage
                                          name={`${stageKey}.${field}.${index}`}
                                          component="div"
                                          className="text-red-600"
                                        />
                                      </div>
                                    )
                                  )}
                                  <button
                                    type="button"
                                    onClick={() => push("")}
                                    className="linear mt-2 rounded-xl bg-green-600 px-4 py-3 font-medium text-white transition duration-200 hover:bg-green-700"
                                  >
                                    Add
                                  </button>
                                </div>
                              )}
                            </FieldArray>
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
              <button
                type="submit"
                disabled={isSubmitting}
                className="mt-6 rounded-xl bg-blue-600 px-6 py-3 text-white transition hover:bg-blue-700"
              >
                {isSubmitting ? "Saving..." : "Save Changes"}
              </button>
            </Form>
          )}
        </Formik>
      ) : (
        <p>Loading...</p>
      )}
    </Card>
  );
};
