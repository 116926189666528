import React, { useEffect, useRef, useState } from "react";
import Card from "components/card";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useLocation } from "react-router-dom";
import { getAllotmentRequest } from "services/appServices";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { Role, STATUS } from "views/customTypes/types";
import { errorToast } from "views/toastConfig";
import Eye from "../../../assets/img/eye.png";
import {
  columnsTable,
  downloadFile,
  handleKeyPress,
} from "helper/helperFunctions";
import { FilterFields } from "components/custom/FilterFields";
import { FiSearch } from "react-icons/fi";
import { Filter } from "assets/jsx/Filter";
import { DownloadButton } from "components/custom/DownloadButton";
import RequestDetails from "../RequestDetailPage/RequestDetails";
import { NoData } from "components/custom/NoData";
interface fieldType {
  key: string;
  type: "text" | "select" | "date";
  label: string;
  placeholder: string;
  options?: { value: string; label: string }[];
  collection: string;
}
let excludeDownloadFields = [
  "password",
  "uid",
  "requestLetter",
  "conversation",
  "bankReceipt",
  "finalPaymentReceipt",
  "isActive",
  "isCustomCharge",
  "resourceData",
  "gstCertificateImg",
  "updatedAt",
  "uid",
  "requestLetter",
  "vacationReport",
  "finalPaymentReceipt",
  "allotmentLetter",
  "isActive",
  "guarantorDetails",
  "resourceId",
  "resourceData",
  "userData",
  "paymentdocuments",
  "finalVerficationDocument",
  "oldRecord",
  "organizationInfo",
  "personnelInfo",
  "emailVerified",
  "mobVerified",
  "aadharCardImg",
  "idCardImg",
  "panCardImg",
  "paymentReferenceDetails",
  "paymentVerficationDocument",
  "accountStatus",
  "allotementStatus",
  "otherDoc",
  "resourceDetails",
  "paySlipImg"

];

export default function RequestOfAllotment() {
  // const { user } = useSelector((store: any) => store.auth);
  const { userRole } = useSelector((store: any) => store.appSlice);

  const location = useLocation();
  const pageType = location.pathname.split("/").pop();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimt] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isPageChanged, setIsPageChanged] = useState<boolean>(false);
  const isInitialRender = useRef(true);
  const [onPageLoadDataLength, setOnPageLoadDataLength] = useState<number>(0);
  const [rowData, setRowData] = useState<any>();
  const [isRequestDetailPageOpen, setIsRequestDetailPageOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  //?filter section ==>
  const [searchFilter, setSearchFilter] = useState<Record<string, Record<string, any>> | Record<string, any>>({});
  const [isFilterOpen, setIsFilteOpen] = useState<boolean>(false);

  useEffect(() => {
    if (Object.keys(searchFilter).length > 0) {
      setCurrentPage(1);
    }
  }, [searchFilter]);

  const filters: fieldType[] = [
    {
      key: "name",
      type: "text",
      label: "Enter Customer Name",
      placeholder: "Enter text",
      collection: "user",
    },

    {
      key: "type",
      type: "text",
      label: "Customer Type",
      placeholder: "Select Type",
      collection: "user",

      // options: [
      //   { value: "jnpaEmployee", label: "JNPA Employee" },
      //   { value: "otherGovtEmployee", label: "Other Govt. Employee" },
      //   { value: "CISF", label: "CISF" },
      //   { value: "other", label: "Other" },
      // ],
    },
    {
      key: "occupation",
      type: "text",
      label: "Enter Occupation",
      placeholder: "Enter text",
      collection: "user",
    },
    // {
    //   key: "date",
    //   type: "date",
    //   label: "Date",
    //   placeholder: "Enter Date",
    //   collection: "allotementRequest",
    // },
  ];

  const getAllotmentReq = async (isDownload = false) => {
    try {
      console.log("getAllotmentReq Called ===>");
      let filter = {};
      //*filter based on current page

      filter = (() => {
        let filter = {};

        switch (pageType) {
          case "request_for_resources":
            if (userRole === Role.CLEARK || userRole == Role.HR_ADMIN) {
              filter = {
                [`allotementStatus.cleark.status`]: STATUS.PENDING,
                [`allotementStatus.cancelRequest.status`]: {
                  $ne: STATUS.CANCELED,
                },
                requestType: "newRequest",
                isActive: true,
              };
            } else if (userRole === Role.MANAGER) {
              filter = {
                [`allotementStatus.${Role.CLEARK}.status`]: STATUS.APPROVED,
                [`allotementStatus.${Role.MANAGER}.status`]: STATUS.PENDING,
                [`allotementStatus.cancelRequest.status`]: {
                  $ne: STATUS.CANCELED,
                },
                isActive: true,
              };
            }
            break;

          case "manager_approval_vacant_pending":
            filter = {
              [`allotementStatus.vacantRequest.status`]:
                STATUS.VERIFICATION_PENDING,
              isActive: true,
            };
            break;

          case "finance_approval_vacant_pending":
            filter = {
              [`allotementStatus.vacantRequest.status`]: STATUS.VERIFIED,
              isActive: true,
            };
            break;

          case "approved_allotment_vacant_requests":
            filter = {
              [`allotementStatus.vacantRequest.status`]: STATUS.APPROVED,
              isActive: false,
            };
            break;

          case "manager_approval_pending":
            filter = {
              [`allotementStatus.${Role.CLEARK}.status`]: STATUS.APPROVED,
              [`allotementStatus.${Role.MANAGER}.status`]: STATUS.PENDING,

              isActive: true,
            };
            break;

          case "clearification_pending":
            filter = {
              [`allotementStatus.${Role.MANAGER}.status`]: STATUS.CLEARIFY,

              isActive: true,
            };
            break;

          case "payment_verification_pending":
            filter = {
              [`allotementStatus.payment.status`]: STATUS.VERIFICATION_PENDING,

              isActive: true,
            };
            break;

          case "payment_verification_approved":
            filter = {
              [`allotementStatus.payment.status`]: STATUS.VERIFIED,
              [`allotementStatus.cleark.status`]: STATUS.APPROVED,
              [`allotementStatus.finance.status`]: { $exists: false },
              isActive: true,
            };
            break;

          case "payment_verification_rejected":
            filter = {
              [`allotementStatus.payment.status`]: STATUS.NOT_VERIFIED,
              [`allotementStatus.cleark.status`]: STATUS.APPROVED,
              [`allotementStatus.finance.status`]: { $exists: false },

              isActive: true,
            };
            break;

          case "finance_approval_pending":
            filter = {
              [`allotementStatus.finance.status`]: STATUS.PENDING,
              [`allotementStatus.cleark.status`]: STATUS.APPROVED,
              [`allotementStatus.manager.status`]: STATUS.APPROVED,
              [`allotementStatus.payment.status`]: STATUS.VERIFIED,

              isActive: true,
            };
            break;
          //todo check not verfied required or not
          case "finance_Approved_request":
            filter = {
              [`allotementStatus.finance.status`]: STATUS.APPROVED,
              [`allotementStatus.payment.status`]: {
                $in: [STATUS.VERIFIED, STATUS.NOT_VERIFIED],
              },
              [`allotementStatus.cleark.status`]: STATUS.APPROVED,
              [`allotementStatus.manager.status`]: STATUS.APPROVED,

              isActive: true,
            };
            break;

          case "approved_allotment_requests":
            filter = {
              [`allotementStatus.${Role.CLEARK}.status`]: STATUS.APPROVED,
              [`allotementStatus.${Role.MANAGER}.status`]: STATUS.APPROVED,
              [`allotementStatus.payment.status`]: STATUS.APPROVED,
              // [`allotementStatus.finance.status`]: STATUS.APPROVED,

              isActive: true,
            };
            break;

          case "manager_approved_requests":
            filter = {
              [`allotementStatus.${Role.CLEARK}.status`]: STATUS.APPROVED,
              [`allotementStatus.${Role.MANAGER}.status`]: STATUS.APPROVED,
              [`allotementStatus.payment.status`]: STATUS.PENDING,
              isActive: true,
            };
            break;

          case "rejected_requests":
            filter = {
              $or: [
                {
                  [`allotementStatus.${Role.MANAGER}.status`]: STATUS.REJECTED,
                },
                { [`allotementStatus.${Role.CLEARK}.status`]: STATUS.REJECTED },
                { [`allotementStatus.payment.status`]: STATUS.REJECTED },
                { [`allotementStatus.finance.status`]: STATUS.REJECTED },
              ],
            };
            break;

          case "canceled_requests":
            filter = {
              [`allotementStatus.cancelRequest.status`]: STATUS.APPROVED,
              isActive: false,
            };
            break;

          case "vacant_resource_request":
            if (userRole === Role.CLEARK || userRole == Role.HR_ADMIN) {
              filter = {
                [`allotementStatus.vacantRequest.status`]: STATUS.PENDING,
                isActive: true,
              };
            } else if (userRole === Role.MANAGER) {
              filter = {
                [`allotementStatus.vacantRequest.status`]:
                  STATUS.VERIFICATION_PENDING,
                isActive: true,
              };
            } else if (userRole === Role.FINANCE) {
              filter = {
                [`allotementStatus.vacantRequest.status`]: STATUS.VERIFIED,
                isActive: true,
              };
            }
            break;

          case "renewal_requests":
            filter = {
              [`allotementStatus.${userRole}.status`]: STATUS.PENDING,
              requestType: "renewalRequest",
              isActive: true,
            };
            break;

          case "change_resource_request":
            filter = {
              [`allotementStatus.${userRole}.status`]: STATUS.PENDING,
              requestType: "changeResouceRequest",
              isActive: true,
            };
            break;

          default:
            console.log(
              "Inside default case, unrecognized pageType:",
              pageType
            );
            break;
        }

        return { ...filter, searchFilter };
      })();

      const res = await getAllotmentRequest(
        filter,
        { updatedAt: -1 },
        !isDownload ? currentPage : null,
        !isDownload ? limit : null
      );
      const { payload, message, error, statusCode } = res?.data || {};

      //? Addind data for form ==>
      if (statusCode == 200) {
        const data: any = payload.documents.map(
          ({ userData, allotmentRequestData, resourceData }: any) => {
            return {
              ...userData,
              ...resourceData,
              ...allotmentRequestData,
              resourceData,
              userData,
            };
          }
        );
        if (isDownload) {
          downloadFile(
            data,
            `Allotement-RequestData.xlsx`,
            excludeDownloadFields
          );
        } else {
          setData(data);
          setTotalCount(payload.counts.totalCount);
        }
      } else {
        console.log({ error });
        errorToast(message);
      }
    } catch (error: any) {
      errorToast(error.response?.data?.message || error.message);
    }
  };

  //?get all user AllotementRequestData ==>
  useEffect(() => {
    console.log("Inside useEffect===>", currentPage, isPageChanged);
    getAllotmentReq();
  }, [currentPage, isPageChanged, refresh]);

  //?refresh ===>
  useEffect(() => {
    if (!isInitialRender.current) {
      setSearchFilter({});
      setIsFilteOpen(false);
      setData([]);
      setTotalCount(0);
      setCurrentPage(1);
      setIsPageChanged((prev) => !prev);
      setIsFilteOpen(false);
      setIsRequestDetailPageOpen(false);
    } else {
      isInitialRender.current = false;
    }
    setOnPageLoadDataLength(0);
  }, [location.pathname]);

  const [data, setData] = useState([]);
  useEffect(() => {
    if (!onPageLoadDataLength) {
      setOnPageLoadDataLength(data.length);
    }
  }, [data.length]);

  const columns = columnsTable(data, [
    "name",
    "mobNo",
    "email",
    "fromDate",
    "tillDate",
    "type",
  ]);

  if (
    !["request_for_resources", "verification_pending"]?.includes(pageType) ||
    userRole != Role.CLEARK
  )
    columns.splice(columns.length, 0, {
      accessor: "VerifiedBy",
      id: "VerifiedBy",
      header: () => (
        <p className="text-center text-sm font-bold  dark:text-white">
          {pageType === "rejected_requests"
            ? "Rejected By"
            : pageType === "approved_allotment_requests"
            ? "Approved By"
            : "Verified By"}
        </p>
      ),
      cell: (info: any) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {(pageType === "rejected_requests"
            ? [
                info.row.original.allotementStatus[Role.CLEARK]?.status,
                info.row.original.allotementStatus?.payment?.status,
              ]?.includes(STATUS.REJECTED)
              ? info.row.original.allotementStatus[Role.CLEARK]?.name
              : info.row.original.allotementStatus[Role.MANAGER]?.name
            : pageType === "approved_allotment_requests"
            ? info.row.original.allotementStatus[Role.MANAGER]?.name
            : info.row.original.allotementStatus[Role.CLEARK]?.name) || "n/a"}
        </p>
      ),
    });
  columns.push({
    accessor: "action",
    id: "action",
    header: () => (
      <p className="text-center text-sm font-bold  dark:text-white">Action</p>
    ),
    cell: (info: any) => (
      <div className="flex items-center justify-center">
        <img
          src={Eye}
          className="hover:cursor-pointer"
          alt="view"
          onClick={() => {
            // navigate("RequestDetails", { state: info.row.original });
            setRowData(info.row.original);
            setIsRequestDetailPageOpen(true);
          }}
        />
      </div>
    ),
  });

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return !isRequestDetailPageOpen ? (
    <>
      <Card extra={"w-full h-full px-6 pb-6 overflow-x-auto"}>
        <div className="  mt-4  flex h-20  md:h-11 md:self-end ">
          <div className="flex h-full w-full flex-col space-y-2 md:flex-row md:space-y-0">
            {/*//* Search section */}
            {onPageLoadDataLength ? (
              <div className="flex items-center justify-between rounded-md bg-gray-100 px-2 md:mr-2 md:h-full">
                <input
                  value={searchFilter?.global || ""}
                  onChange={(e) => {
                    setSearchFilter((prev) => ({
                      ...prev,
                      global: e.target.value ,
                    }));
                  }}
                  onKeyDown={(e) => handleKeyPress(e, getAllotmentReq)}
                  type="text"
                  placeholder="Search..."
                  className="text-md block h-[2.4rem]  bg-gray-100 font-medium text-navy-700 outline-none placeholder:text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:text-white  md:w-fit"
                />
                <div
                  onClick={() => {
                    searchFilter.global && getAllotmentReq();
                  }}
                  className="flex cursor-pointer"
                >
                  <FiSearch />
                </div>
              </div>
            ) : (
              <></>
            )}
              <div className="flex h-[4.8rem] flex-col space-y-2 md:h-full md:flex-row-reverse md:space-y-0 ">
                <div className="flex flex-nowrap  items-center  justify-between space-x-1 md:mr-2 md:h-full">
            {data.length > 0 && (
                  <DownloadButton
                    data={data}
                    getAllData={getAllotmentReq}
                    excludeFields={excludeDownloadFields}
                    fileName={`Allotement-RequestData.xlsx`}
                  />
                )}
                  <div
                    className=" cursor-pointer shadow-md shadow-[#d4e9ff]"
                    onClick={() => setIsFilteOpen((prev) => !prev)}
                  >
                    <Filter />
                  </div>
                </div>
              </div>
          </div>
        </div>

        {isFilterOpen && (
          <FilterFields
            filters={filters}
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
            cancleFunction={setIsFilteOpen}
            searchFunction={getAllotmentReq}
            onKeyDown={(e: any) => handleKeyPress(e, getAllotmentReq)}
          />
        )}

        <div className="mt-8 min-h-[70lvh]  overflow-x-auto">
          {data.length > 0 && (
            <table className="w-full">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className="!border-px !border-gray-400"
                  >
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          className="mb-2 cursor-pointer border-b-[1px] border-gray-200 bg-gradient-to-t from-[#ffffff] to-[#c7fdff] py-2 text-center align-top shadow-lg"
                        >
                          <div className="items-center justify-between  text-center text-xs text-[#00353C] ">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: "",
                              desc: "",
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table
                  .getRowModel()
                  .rows.slice(0, limit)
                  .map((row) => {
                    return (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              className="border-x border-y-0 px-1 py-3 text-center"
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
          <NoData isVisible={data.length == 0} label={"No Data found!"} />
        </div>
      </Card>
      {data.length > 0 && (
        <ReactPaginate
          className="mt-2 flex justify-center gap-2"
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          pageCount={Math.ceil(totalCount / limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={({ selected }) => setCurrentPage(selected + 1)}
          containerClassName={"pagination"}
          activeClassName={"!text-primaryColor-dark !font-bold "}
        />
      )}
    </>
  ) : (
    <RequestDetails
      rowData={rowData}
      backFuction={setIsRequestDetailPageOpen}
      refreshFunction={getAllotmentReq}
      setRefresh={setRefresh}
      setRowData={setRowData}
    />
  );
}
