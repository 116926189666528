import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { setRequestCountForPages } from "redux/reducers/appReducer";
import {
  downloadFileByPath,
  generatePdfFromHtml,
  getCountForPages,
  viewPdfFromHtml,
} from "services/appServices";
import { errorToast } from "views/toastConfig";

const XLSX = require("sheetjs-style");
const FS = require("file-saver");
export const snakeToCamelCase = (str) => {
  if (str.endsWith("s")) str = str.slice(0, -1);
  return str.replace(/_([a-z])/g, (_, match) => match.toUpperCase());
};

//* request_for_resources
export const convertToOptions = (values) => {
  if (!values) return [];
  return values.map((val) => ({ value: val, label: val }));
};

export const formatDateField = (str) => {
  if (!str) return "n/a";
  else {
    return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(str)
      ? new Date(str).toLocaleDateString()
      : str;
  }
};

export const formatHeader = (key) => {
  if (typeof key != "string") return key;
  // console.log({ key });
  //? Split the key by capital letters or underscore
  const words = key?.split(/(?=[A-Z])|_/);
  // console.log("words", words);
  //? Capitalize the first letter of each word
  const formattedKey = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return formattedKey;
};

export const columnsTable = (
  data,
  columnsToInclude,
  columnsToExclude,
  sortColumns
) => {
  if (!data || data.length === 0) return [];

  let columns = Object.keys(data[0])
    .filter((key) => {
      if (columnsToExclude && columnsToExclude.length > 0) {
        return !columnsToExclude.some((excludeKey) => excludeKey === key); // Exclude specified keys
      }
      if (columnsToInclude && columnsToInclude.length > 0) {
        return columnsToInclude.some((includeKey) => includeKey === key); // Include only specified keys
      }
      return true; // No filter applied, include all columns
    })
    .map((key) => ({
      accessor: key,
      id: key,
      header: () => (
        <p className="text-sm font-bold dark:text-white">{formatHeader(key)}</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {formatObject(formatDateField(info.row.original[key])) || "n/a"}
        </p>
      ),
    }));

  if (sortColumns && sortColumns.length > 0) {
    columns = columns.sort((a, b) => {
      const aIndex = sortColumns.indexOf(a.accessor);
      const bIndex = sortColumns.indexOf(b.accessor);
      if (aIndex === -1 && bIndex === -1) return 0;
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });
  }

  return columns;
};

export const formatObject = (obj) => {
  if (typeof obj !== "object") return obj;
  return Object.entries(obj || {})
    .map(([key, value]) => `${value}`)
    .join(", ");
};

export const generateDataFields = (
  data = [],
  keysToInclude = [],
  keysToExclude = [],
  customStyle = { container: "", header: "", value: "" },
  sortOrder = [] // New parameter for sorting
) => {
  let keys;

  // Check if payment status is "alloted"

  if (keysToInclude?.length) {
    keys = keysToInclude.filter((key) => data.hasOwnProperty(key));
  } else {
    keys = Object.keys(data);

    if (keysToExclude.length > 0) {
      keys = keys.filter((key) => !keysToExclude?.includes(key));
    }
  }
  // Sort keys based on the sortOrder parameter
  if (sortOrder.length > 0) {
    keys = keys.sort((a, b) => sortOrder.indexOf(b) - sortOrder.indexOf(a));
  }

  return keys.map((key) => (
    <div key={key} className={`flex md:flex-col  ${customStyle.container}`}>
      <label className={`font-medium text-[#1e3947] ${customStyle.header}`}>
        {key == "fatherOrHusbandName"
          ? "Father/Husband Name"
          : formatHeader(key)}
      </label>
      <label
        className={`max-w-full overflow-auto text-[#1e6268] ${
          customStyle.value
        } ${
          [
            "requestType",
            "status",
            "dateOfPossession",
            "possessionEndDate",
          ].includes(key) && "font-bold text-blue-500"
        } `}
      >
        {typeof data[key] === "object"
          ? formatObject(data[key])
          : ["requestType", "status"].includes(key)
          ? formatHeader(data[key])
          : !["email"].includes(key)
          ? formatDateField(data[key])
          : data[key] || "n/a"}
      </label>
    </div>
  ));
};

export const handleKeyPress = async (event, getData) => {
  if (event.key === "Enter") {
    getData();
  }
};

//* Download in xlsx
export const downloadFile = async (
  downLoadData,
  name = "Data.xlsx",
  excludeFields = []
) => {
  console.log("Downloading Data ....", { downLoadData });

  // Filter out the fields to be excluded and replace missing or empty fields with "N/A"
  const filteredData = downLoadData.map((item) => {
    const newItem = {};
    for (const key in item) {
      if (!excludeFields?.includes(key)) {
        newItem[key] =
          item[key] !== undefined && item[key] !== ""
            ? formatObject(formatDateField(item[key]))
            : "n/a";
      }
    }
    // Add "n/a" for fields that are not present in the current item but are in other items
    Object.keys(downLoadData[0]).forEach((key) => {
      if (!(key in newItem) && !excludeFields?.includes(key)) {
        newItem[key] = "n/a";
      }
    });
    return newItem;
  });

  let filetype =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const ws = XLSX.utils.json_to_sheet(filteredData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelb = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelb], { type: filetype });
  if (window?.ReactNativeWebView) {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onloadend = () => {
      const base64data = reader.result;
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          payload: {
            name,
            dataUrl: base64data.split(
              "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;base64,"
            )[1],
            type: "xlsx",
            filetype: "xlsx",
          },
        })
      );
    };
    // add return here
  }

  FS.saveAs(data, `${name}.xlsx`);
};

//* Download in pdf

export const downloadFilePdf = async (
  downLoadData,
  fileName = "Data.pdf",
  excludeFields = [],
  customHeaders = {}
) => {
  console.log("Downloading Data ....", { downLoadData });

  // Filter out the fields to be excluded and replace missing or empty fields with "n/a"
  const filteredData = downLoadData.map((item) => {
    const newItem = {};
    for (const key in item) {
      if (!excludeFields?.includes(key)) {
        newItem[key] =
          item[key] !== undefined && item[key] !== ""
            ? formatDateField(item[key])
            : "n/a";
      }
    }
    return newItem;
  });

  const doc = new jsPDF();

  // Add a title
  const titleFontSize = 16;
  const titleTextWidth =
    (doc.getStringUnitWidth(fileName) * titleFontSize) /
    doc.internal.scaleFactor;
  const titleX = (doc.internal.pageSize.width - titleTextWidth) / 2;
  const titleY = 20; // Adjust as needed
  doc.setFontSize(titleFontSize);
  doc.setFont("helvetica", "bold");
  doc.text(fileName, titleX, titleY);

  // Add the date at the top right corner with padding
  const dateFontSize = 8;
  const dateText = new Date().toLocaleString();
  const dateTextWidth =
    (doc.getStringUnitWidth(dateText) * dateFontSize) /
    doc.internal.scaleFactor;
  const dateX = doc.internal.pageSize.width - dateTextWidth - 10; // Adjust padding from right
  const dateY = 15; // Adjust as needed
  doc.setFontSize(dateFontSize);
  doc.setFont("helvetica", "normal"); // Restore normal font style
  doc.text(dateText, dateX, dateY);

  const tableBody = filteredData.flatMap((item, recordIndex) => {
    return Object.keys(item).map((key) => {
      return [
        ++recordIndex,
        customHeaders[key] || formatHeader(key),
        formatObject(item[key]),
      ];
    });
  });

  doc.autoTable({
    startY: 30,
    head: [["Sr. No", "Field", "Value"]],
    body: tableBody,
    theme: "grid", // 'striped', 'grid' or 'plain'
    styles: {
      font: "helvetica", // 'helvetica', 'times', 'courier'
      fontSize: 10,
      cellPadding: 3,
      overflow: "linebreak", // Wrap text to the next line if exceeds cell width
      halign: "center", // 'left', 'center', 'right'
      valign: "middle", // 'top', 'middle', 'bottom'
      fillColor: "#F4FEFF",
      textColor: [0, 0, 0],
      lineWidth: 0.1,
      lineColor: [0, 0, 0],
    },
    headStyles: {
      fillColor: [22, 160, 133],
      textColor: [255, 255, 255],
      fontStyle: "bold",
    },
    alternateRowStyles: {
      fillColor: "#EAFEFF",
    },
    columnStyles: {
      0: { halign: "left" },
      1: { halign: "left" },
      2: { halign: "left" },
    },
    didDrawPage: function (data) {
      // Add custom footer
      const pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      doc.text(
        `Page ${doc.internal.getNumberOfPages()}`,
        data.settings.margin.left,
        pageHeight - 10
      );
    },
  });
  if (window?.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        payload: {
          fileName,
          dataUrl: doc.output("dataurlstring").split(",")[1],
          type: "pdf",
        },
      })
    );
  } else {
    doc.save(`${fileName}.pdf`);
  }
};

export const downloadFilePdfFromHtml = async (
  downLoadData,
  name = "Data.pdf",
  excludeFields = [],
  customHeaders = {}
) => {
  console.log("Downloading Data ....", { downLoadData });
  try {
    const resPdfFromHtml = await generatePdfFromHtml(downLoadData);
    if (resPdfFromHtml?.data) {
      console.log({ data: resPdfFromHtml.data });
      const base64Data = resPdfFromHtml.data;
      //todo check ? is necessary or not
      if (window?.ReactNativeWebView) {
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            payload: {
              name,
              dataUrl: base64Data.split(",")[0],
              type: "pdf",
              filetype: "pdf",
            },
          })
        );
      } else {
        const binaryString = window.atob(base64Data);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) bytes[i] = binaryString.charCodeAt(i);
        const blob = new Blob([bytes], { type: "application/pdf" });

        saveAs(blob, `downloaded_file.pdf`);
        console.log({ resPdfFromHtml });
      }
    }
  } catch (error) {
    console.log(errorToast(error.message || "Somethin went wrong!"));
    console.log("Error downloadFilePdfFromHtml ==>", { error });
  }
};

export const uploadFilePdfFromHtml = async (
  downLoadData,
  uploadFileFunction,
  name = "Data.pdf",
  excludeFields = [],
  customHeaders = {}
) => {
  console.log("Downloading Data ....", { downLoadData });
  try {
    const resPdfFromHtml = await generatePdfFromHtml(downLoadData);
    if (resPdfFromHtml?.data) {
      console.log({ data: resPdfFromHtml.data });
      const base64Data = resPdfFromHtml.data;
      const binaryString = window.atob(base64Data);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) bytes[i] = binaryString.charCodeAt(i);
      const blob = new Blob([bytes], { type: "application/pdf" });
      if (blob) {
        uploadFileFunction(blob, "allotmentLetter");
      }
    }
  } catch (error) {
    console.log(errorToast(error.message || "Somethin went wrong!"));
    console.log("Error downloadFilePdfFromHtml ==>", { error });
  }
};

export const openFileFromHtml = async (downLoadData) => {
  console.log("Downloading Data ....", { downLoadData });
  try {
    const resPdfFromHtml = await viewPdfFromHtml(downLoadData);
    if (resPdfFromHtml?.data) {
      return resPdfFromHtml.data;
    }
  } catch (error) {
    console.log(errorToast(error.message || "Somethin went wrong!"));
    console.log("Error downloadFilePdfFromHtml ==>", { error });
  }
};

//! not using right now
export const handleDownloadFile = async (
  path,
  type,
  viewOnly = false,
  name
) => {
  try {
    console.log("Attempting to download file", { path, type });
    const response = await downloadFileByPath(path);

    if (response?.data) {
      const base64Data = response.data;

      const binaryString = window.atob(base64Data);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) bytes[i] = binaryString.charCodeAt(i);
      const blob = new Blob([bytes], { type });
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            payload: {
              fileName: name || "Data",
              dataUrl: base64Data.split(",")[0],
              type: type.split("/")[1],
            },
          })
        );
      } else {
        if (viewOnly) {
          const url = window.URL.createObjectURL(blob);
          window.open(url);
          setTimeout(() => window.URL.revokeObjectURL(url), 1000); // Revoke after 1 second
        } else {
          saveAs(blob, `downloaded_file.${type.split("/")[1]}`);
        }
      }
    } else {
      console.error("Failed to download file. Status:", response.status);
      throw new Error(response?.data?.message || "Failed to download file");
    }
  } catch (error) {
    console.error("Error downloading file:", error, error.name, error.code);
    errorToast(
      error.name == "InvalidCharacterError"
        ? "File Not Found!"
        : error.response?.data?.message || error.message
    );
  }
};

export const downloadFromS3 = (url, name, filetype, download = false) => {
  console.log("downloadFromS3 ==>", { url });
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        payload: {
          dataUrl: url,
          type: "s3",
          filetype,
          name,
        },
      })
    );
  } else {
    // Create a new anchor element
    // Create a new anchor element
    const link = document.createElement("a");

    // Set the href attribute to the provided URL
    link.href = url;

    // Determine if the file should be downloaded or viewed in the browser
    if (download) {
      // Set the download attribute to specify a default filename for the downloaded file
      link.setAttribute("download", "");
    } else {
      // Set target="_blank" to open the URL in a new tab
      link.setAttribute("target", "_blank");
    }

    // Append the anchor element to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the action (download or open in new tab)
    link.click();

    // Remove the anchor element from the body
    document.body.removeChild(link);
  }
};

export const alphanumericSort = (a, b) => {
  const aParts = a.match(/(\d+|\D+)/g) || [];
  const bParts = b.match(/(\d+|\D+)/g) || [];
  const len = Math.min(aParts.length, bParts.length);

  for (let i = 0; i < len; i++) {
    const aPart = aParts[i];
    const bPart = bParts[i];

    if (aPart !== bPart) {
      const aIsNumeric = !isNaN(parseInt(aPart));
      const bIsNumeric = !isNaN(parseInt(bPart));

      if (aIsNumeric && bIsNumeric) {
        return parseInt(aPart) - parseInt(bPart);
      } else if (aIsNumeric) {
        return 1;
      } else if (bIsNumeric) {
        return -1;
      } else {
        return aPart.localeCompare(bPart);
      }
    }
  }

  return aParts.length - bParts.length;
};

export const pieChartOptions = {
  labels: ["Alloted", "Vacant", "Applied"],
  colors: ["#2CA8E2", "#33E064", "#9C12CA"],
  chart: {
    width: "50px",
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  legend: {
    show: true,
  },
  dataLabels: {
    enabled: false,
  },
  hover: { mode: null },
  plotOptions: {
    donut: {
      expandOnClick: true,
      donut: {
        labels: {
          show: true,
        },
      },
    },
  },
  fill: {
    colors: ["#2CA8E2", "#33E064", "#9C12CA"],
  },
  tooltip: {
    enabled: true,
    theme: "dark",
    style: {
      fontSize: "12px",
      fontFamily: undefined,
      backgroundColor: "#000000",
    },
  },
};

export const getCountForAllAdminPages = async (role, dispatch) => {
  const count = await getCountForPages(role).catch((err) =>
    console.log("Error while fetching Count for all Pages", { err })
  );
  dispatch(setRequestCountForPages(count?.data || ""));
};
export const getMinMonth = () => {
  const today = new Date();
  const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
  return nextMonth.toISOString().slice(0, 7);
};
