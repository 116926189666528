import { Role, STATUS } from "views/customTypes/types";

export const ButtonsSec = ({
  pageType,
  backFuction,
  setIsChatVisible,
  userRole,
  isChatVisible,
}: {
  pageType: any;
  backFuction: any;
  setIsChatVisible: any;
  userRole: string;
  isChatVisible: any;
}) => {
  return (
    <div className="flex  flex-col-reverse  md:flex-row md:space-x-2 md:self-end md:p-8 ">
      <button
        type={"button"}
        onClick={() => {
          // navigate(-1);
          backFuction(false);
        }}
        className=" mt-2 min-w-32 rounded-xl bg-gradient-to-r from-gray-300 to-gray-400 py-2 text-base  font-medium text-white shadow-md transition duration-200 hover:bg-gradient-to-t hover:to-gray-500 hover:shadow-xl active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:py-2"
      >
        Back
      </button>
      {(([
        "request_for_resources",
        "vacant_resource_request",
        "payment_verification_rejected",
        "change_resource_request",
        "clearification_pending",
        "manager_approved_requests",
        "payment_verification_pending",
      ].includes(pageType) &&
        userRole == Role.CLEARK) ||
        (["payment_verification_pending"].includes(pageType) &&
          userRole == Role.FINANCE)) && (
        <button
          onClick={() => setIsChatVisible((prev: any) => !prev)}
          type="button"
          className="linear mt-2 min-w-32 rounded-xl bg-gradient-to-r from-blue-400 to-blue-500 py-2 text-base font-medium text-white shadow-md transition duration-200 hover:bg-blue-700 hover:bg-gradient-to-t hover:to-blue-600 hover:shadow-xl active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:py-2"
        >
          {isChatVisible ? "Close Chat" : "Start Chat"}
        </button>
      )}

      {[
        "request_for_resources",
        "verification_pending",
        "payment_verification_pending",
        "payment_verification_approved",
        "finance_approval_pending",
        "manager_approved_requests",
        "finance_Approved_request",
        "payment_verification_rejected",
        "clearification_pending",
        "vacant_resource_request",
        "renewal_requests",
        "change_resource_request",
      ]?.includes(pageType) &&
        !(
          ([
            "manager_approved_requests",
            "payment_verification_pending",
            "payment_verification_approved",
            "finance_approval_pending",
            "manager_approved_requests",
            "finance_Approved_request",
            "payment_verification_rejected",
            "clearification_pending",
          ].includes(pageType) &&
            userRole == Role.MANAGER) ||
          ([
            "payment_verification_pending",
            "finance_approval_pending",
          ].includes(pageType) &&
            userRole == Role.CLEARK) ||
          ([
            "payment_verification_approved",
            "finance_Approved_request",
            "payment_verification_rejected",
          ].includes(pageType) &&
            userRole == Role.FINANCE)
        ) &&
        userRole !== Role.HR_ADMIN && (
          <>
            {pageType != "vacant_resource_request" && (
              <button
                data-type={
                  pageType == "payment_verification_pending"
                    ? STATUS.NOT_VERIFIED
                    : STATUS.REJECTED
                }
                type="submit"
                className="linear mt-2 rounded-xl bg-gradient-to-r from-red-400  to-red-500 py-2 text-base font-medium text-white shadow-md transition duration-200 hover:bg-gradient-to-t  hover:to-red-600 hover:shadow-xl active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:min-w-32 md:py-[12px]"
              >
                Reject
              </button>
            )}
            {userRole == Role.CLEARK &&
              ![
                "payment_verification_approved",
                "finance_Approved_request",
                "request_for_resources",
                "clearification_pending",
                "vacant_resource_request",
                "renewal_requests",
                "change_resource_request",
              ].includes(pageType) && (
                <button
                  data-type={STATUS.VERIFICATION_PENDING}
                  type="submit"
                  className="linear mt-2 rounded-xl bg-gradient-to-r from-orange-300 to-orange-500 px-4 py-2 text-base font-medium text-white shadow-md transition duration-200 hover:bg-gradient-to-t hover:to-orange-700  hover:shadow-xl  active:bg-brand-700 dark:active:bg-brand-200 md:min-w-[15rem] md:py-[12px]"
                >
                  Send for Payment Verification to Finance
                </button>
              )}

            {userRole == Role.MANAGER &&
              ["request_for_resources"].includes(pageType) && (
                <button
                  data-type={STATUS.CLEARIFY}
                  type="submit"
                  className="linear mt-2 rounded-xl bg-gradient-to-r from-orange-300 to-orange-500 py-2 text-base font-medium text-white shadow-md transition duration-200 hover:bg-gradient-to-t hover:to-orange-700 hover:shadow-xl  active:bg-brand-700  dark:active:bg-brand-200 md:min-w-[13rem] md:py-[12px]"
                >
                  Add to Clarify
                </button>
              )}
            {![
              "manager_approved_requests",
              "payment_verification_rejected",
            ].includes(pageType) && (
              <button
                data-type={
                  pageType == "payment_verification_pending"
                    ? STATUS.VERIFIED
                    : STATUS.APPROVED
                }
                type="submit"
                className="linear mt-2 rounded-xl bg-gradient-to-r from-green-400 to-green-500 px-4 py-2 text-base font-medium text-white shadow-md transition duration-200 hover:bg-gradient-to-t hover:to-green-700  hover:shadow-xl  active:bg-brand-700 dark:active:bg-brand-200 md:min-w-[10rem] md:py-[12px]"
              >
                {userRole === Role.CLEARK
                  ? [
                      "request_for_resources",
                      "clearification_pending",
                      "renewal_requests",
                    ].includes(pageType)
                    ? "Send to Manager for Approval"
                    : pageType === "payment_verification_approved"
                    ? "Send for Finance Approval"
                    : "Approve"
                  : "Approve"}
              </button>
            )}
          </>
        )}
    </div>
  );
};
