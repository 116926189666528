import customAxios from "./customAxios";

export const createUserApi = async (data: any) => {
  console.log("createUserApi called==>");
  return customAxios.post(`customers/signUp`, data);
};
export const LoginApi = (data: any) => {
  console.log("LoginApi called==>");
  return customAxios.post("users/login", data);
};

export const DeleteApi = (_id: string, role: string, doneBy?: string) => {
  console.log("DeleteApi called==>", { doneBy });
  return customAxios.delete(`/users/deleteUser`, {
    data: { _id, role, doneBy },
  });
};

export const userService = (data: any) => {
  console.log("DeleteApi called==>");
  return customAxios.delete("/customers/signUp", data);
};

//*ctthis is using to update personnel or organization info
export const updateUser = (
  updateUserdata: any,
  filter: any,
  action?: string
) => {
  console.log("updateUser called with:", { updateUserdata });

  if (action) {
    return customAxios.patch(
      `customers/updateUser/${JSON.stringify(filter)}/${action}`,
      updateUserdata,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } else {
    return customAxios.patch(`customers/${filter}`, updateUserdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
};

//?find user request

//?add user admin
export const adduser = async (data: any) => {
  console.log("addUser api called==>");

  return customAxios.post(`/admin/addUser`, data);
};
export const updateUserAdmin = async (data: any) => {
  console.log("updateUserAdmin api called==>");

  return customAxios.patch(`/admin/updateUser`, data);
};

export const getUsers = async (filter?: any, page?: number, limit?: number) => {
  console.log("getUsers api called==>");

  return customAxios.post(`/admin/getUsers`, {
    data: { filter, page, limit },
  });
};

//! unused Api
export const deleteUser = async (_id: any) => {
  console.log("getUsers api called==>");
  return customAxios.delete(`/admin/deleteUser`, { data: { _id } });
};

//*Resource Releaated Api's

export const getAllResources = async (
  filter: any,
  page?: number,
  limit?: number
) => {
  console.log("getResources api called==>", { filter });
  const resData = await customAxios.post(`resources/getResources`, {
    data: { filter, page: page, limit: limit },
  });
  return resData;
};
export const addResource = async (data: any) => {
  console.log("addResource api called==>", { data });

  return customAxios.post(`resources/addResource`, data);
};
export const updateResource = async (data: any) => {
  console.log("updateResource api ==>", { data });
  return customAxios.post(`resources/updateResource`, data);
};
//?for Admin
export const deleteResource = async (_id: any) => {
  console.log("getUsers api ==>");
  return customAxios.delete(`resources/deleteResource`, { data: { _id } });
};

//*Allotement Api's

export const findUserAllotmentRequest = (filter: any, sort?: any) => {
  console.log("findUserAllotmentRequest api==>", { filter, sort });

  return customAxios.get(`allotmentRequest/findUserAllotmentRequest`, {
    params: {
      data: JSON.stringify({
        filter,
        sort,
      }),
    },
  });
};
export const addResourceAllotmentRequest = (data: any) => {
  console.log("addResourceAllotmentRequest api ==>", data);

  return customAxios.post("allotmentRequest/create", data);
};

export const renewalAllotmentRequest = (data: any) => {
  console.log("renewalAllotmentRequest api ==>", { data });
  return customAxios.post("allotmentRequest/renewalCreate", data);
};

export const changeResourceRequest = (data: any) => {
  console.log("changeResourceRequest api ==>", { data });
  return customAxios.post("allotmentRequest/changeResourceRequest", data);
};
export const getAllotmentRequest = async (
  filter: any,
  sort: object,
  page?: number,
  limit?: number
) => {
  console.log("getAllotmentRequest api called==>", {
    filter,
    sort,
    page,
    limit,
  });

  return customAxios.post(`allotmentRequest/getAllotmentRequest`, {
    data: { filter, page: page, limit: limit, sort },
  });
};

export const getCountForPages = (role: string) => {
  console.log("getUserAndAllotementDataByResId api ==>", { role });
  return customAxios.get(`allotmentRequest/getCountForPages`, {
    params: {
      role,
    },
  });
};

// todo check is it used somewher or not if not remove it
export const getAllotmentRequestCleark = async (
  filter?: any,
  page?: number,
  limit?: number
) => {
  console.log("getAllotmentRequest api called==>");

  return customAxios.post(`allotmentRequest/getAllotmentRequestcleark`, {
    data: { filter, page: page || 1, limit: limit || 10 },
  });
};
//end=============================>

// todo change name
export const allotmentRequestAction = async (data: any) => {
  console.log("allotmentRequestAction api called==>");
  return customAxios.post(`allotmentRequest/approvalReq`, data);
};

//* csv related routes
export const addResourceFromCsv = async (formData: any) => {
  console.log("addResourceFromCsv api ==>", formData);
  return customAxios.post(`resources/addResourceFromCsv`, formData);
};

//todo try to avoid this

//todo chek is it user or not if not remove it
//* csv related routes
export const getUserAndAllotementDataByResId = async (_id: any) => {
  console.log("getUserAndAllotementDataByResId api ==>", _id);
  return customAxios.get(`resources/getUserAndAllotementDataByResId/${_id}`);
};

//*temp

export const getSuggestionForbldType = async (bldType: any) => {
  console.log("getSuggestionForbldType api ==>", bldType);
  return customAxios.get(
    `resources/getSuggestionforBuildingType/${JSON.stringify(bldType)}`
  );
};

export const getSuggestionBasedRentalUnit = async (filter: any) => {
  console.log("getSuggestionBasedRentalUnit api ==>", filter);
  return customAxios.get(
    `resources/getSuggestionBasedRentalUnit/${JSON.stringify(filter)}`
    // { responseType: "arraybuffer" }
  );
};

export const conversation = async (convesationData: any) => {
  console.log("conversation api ==>", { convesationData });
  return customAxios.post(`allotmentRequest/conversation`, convesationData);
};

export const updateUserWithDoc = async (userData: any) => {
  console.log("conversation api ==>", { userData });
  return customAxios.post(`customers/updateUserWithDoc`, userData);
};
export const uploadFileIntoAllotementRequest = async (uploadData: any) => {
  console.log("uploadFileIntoAllotementRequest api ==>", { uploadData });
  return customAxios.post(
    `allotmentRequest/uploadFileIntoAllotementRequest`,
    uploadData
  );
};

export const findByIdAllotmentRequest = async (_id: string, project: any) => {
  console.log("findByIdAllotmentRequest api ==>", { _id, project });
  return customAxios.get(`allotmentRequest/getallotementRequestById`, {
    params: {
      _id,
      project: JSON.stringify(project),
    },
  });
};
export const downloadFileByPath = async (path: string) => {
  console.log("downloadFileByPath api ==>", path);
  return customAxios.get(`users/downloadFileByPath?path=${path}`);
};

export const getOccupationSuggestions = async () => {
  console.log("getOccupationSuggestions api ==>");
  return customAxios.get(`users/getOccupationSuggestions`);
};

export const sendOtp = async (data: {
  uid: string;
  email: string;
  type: string;
}) => {
  console.log("sendOtp api ==>", data);
  return customAxios.post(`users/sendOtp`, data);
};
export const validateOtp = async (data: {
  uid: string;
  otp: string;
  email?: string;
  mobNo?: string;
  type?: string;
  data?: object;
}) => {
  console.log("verifyOtp api ==>", data);
  return customAxios.patch(`users/validateOtp`, data);
};

export const getDataForPieChart = async (filter?: object, project?: object) => {
  console.log("getDataForPieChart api ==>", { filter, project });
  return customAxios.get(`resources/getDataForPieChart`, {
    // params: {
    //   filter: JSON.stringify(filter),
    //   project: JSON.stringify(project),
    // },
  });
};

export const createResourceCharges = async (data: {
  chargesType: string;
  rent: string;
  waterCharges: string;
  maintenance: string;
  resourceDetails: object;
}) => {
  console.log("createResourceCharges ==>", data);
  return customAxios.post(`admin/createResourceCharges`, data);
};

export const getSettingData = async (
  filter: object,
  sort: object,
  project?: object
) => {
  console.log("getSettingData ==>", { filter, project, sort });
  return customAxios.post(`admin/getSettingData`, { filter, project, sort });
};

export const getAllChargesHistory = async (
  filter: object,
  fetchAllDoc: boolean,
  fetchLatestDoc: boolean,
  sort?: object,
  limit?: number,
  currentPage?: number,
  project?: object
) => {
  console.log("getAllChargesHistory ==>", { filter, project, sort, limit });
  return customAxios.get(`admin/getAllChargesHistory`, {
    params: {
      data: JSON.stringify({
        filter,
        sort,
        limit,
        currentPage,
        project,
        fetchLatestDoc,
        fetchAllDoc,
      }),
    },
  });
};

export const getEmailList = async () => {
  console.log("getEmailList api ==>");
  return customAxios.get(`admin/getEmailList`);
};

export const updateEmailList = async (data: any) => {
  console.log("updateEmailList api ==>");
  return customAxios.post(`admin/updateEmailList`, data);
};

export const generatePdfFromHtml = async (data: any) => {
  console.log("generatePdfFromHtml api ==>");
  return customAxios.get(`users/generatePdfFromHtml`, {
    params: data,
  });
};

export const viewPdfFromHtml = async (data: any) => {
  console.log("viewPdfFromHtml api ==>");
  return customAxios.get(`users/viewPdfFromHtml`, {
    params: data,
  });
};

export const generateCustomerCode = async (data: any) => {
  console.log("generateCustomerCode api ==>");
  return customAxios.post(`allotmentRequest/generateCustomerCode`, data);
};

export const downloadUserStatusReport = async (data: any) => {
  console.log("downloadUserStatusReport api ==>");
  return customAxios.post(`allotmentRequest/downloadUserStatusReport`, data);
};
